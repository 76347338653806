<template></template>
<script>
import routes from '@/store/modules/routes';
import AuthService from '@/services/AuthService';

export default {
  name: 'OidcCopel',
  mounted() {
    this.loginRequired();
  },
  methods: {
    loginRequired() {
      const keycloak = AuthService.getKeycloakInstance(
        'norven-geo-copel',
        'oidc-copel'
      );
      keycloak
        .init({
          onLoad: 'login-required'
        })
        .then(() => this.loginUser(keycloak));
    },
    async loginUser(keycloak) {
      const {
        authServerUrl: keycloakHost,
        realm: keycloakRealm,
        token: keycloakToken
      } = keycloak;
      try {
        await this.$store.dispatch('loginOidcCopel', {
          keycloakHost,
          keycloakRealm,
          keycloakToken
        });
        this.$router.push({
          name: routes.state.appRoutes['HOME']
        });
      } catch (error) {
        this.$toast.error('Erro ao tentar logar o usuário na aplicação.', '', {
          position: 'topRight'
        });
        console.error(error);
        this.$router.push('/login');
      }
    }
  }
};
</script>
